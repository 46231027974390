<template>
  <div class="instructions">
    <div class="top">
      <router-link to="/"><img class="back" :src="Back" alt=""></router-link>
    </div>
    <div class="steps">
    <div class="step" v-show="mainStore.currentStep === 1">
      <h1>{{ $t("message.paso_1") }}</h1>
      <p>{{ $t("message.step_1") }}</p>
      <img :src="Step1" alt="">
    </div>

    <div class="step" v-show="mainStore.currentStep === 2">
      <h1>{{ $t("message.paso_2") }}</h1>
      <p>{{ $t("message.step_2") }}</p>
      <img :src="Step2" alt="">
    </div>

    <div class="step" v-show="mainStore.currentStep === 3">
      <h1>{{ $t("message.paso_3") }}</h1>
      <p>{{ $t("message.step_3") }}</p>
      <img :src="Step3" alt="">
    </div>

    <div class="step" v-show="mainStore.currentStep === 4">
      <h1>{{ $t("message.paso_4") }}</h1>
      <p>{{ $t("message.step_4") }}</p>
      <img :src="Step4" alt="">
    </div>

    <div class="step" v-show="mainStore.currentStep === 5">
      <h1>{{ $t("message.paso_5") }}</h1>
      <p>{{ $t("message.step_5") }}</p>
      <img :src="Step5" alt="">
    </div>

    <div class="step" v-show="mainStore.currentStep === 6">
      <h1>{{ $t("message.paso_6") }}</h1>
      <p>{{ $t("message.step_6") }}</p>
      <img :src="Step6" alt="">
    </div>
    <div class="pagination">
    <button class="btn_next" @click="goNext()">{{this.mainStore.currentStep < 6 ? $t("message.continue_button") : $t("message.start_button") }}</button>
    <!--<button @click="goBack()">Back</button>-->
  </div>
  </div>
  </div>
  
</template>

<script>
import {useMainStore} from '../stores/mainStore';

import Step1 from "../assets/step_1.svg";
import Step2 from "../assets/step_2.svg";
import Step3 from "../assets/step_3.svg";
import Step4 from "../assets/step_4.svg";
import Step5 from "../assets/step_5.svg";
import Step6 from "../assets/step_6.svg";
import Back from "../assets/arrow_back.svg";

  export default{
    name:'InstructionsView',
    setup(){
      const mainStore = useMainStore();
      return {mainStore}
    },
    data: function(){
      return{
        Step1: Step1,
        Step2: Step2,
        Step3: Step3,
        Step4: Step4,
        Step5: Step5,
        Step6: Step6,
        Back
      }
    },
    methods:{
      goNext: function(){
        if(this.mainStore.currentStep >= 6){
          this.mainStore.currentStep = 1;
          this.$router.push('/start')
        }
        else{
          this.mainStore.currentStep++
          console.log(this.mainStore.currentStep)
        }
      },
      goBack: function(){
        if(this.mainStore.currentStep <= 1){
          this.mainStore.currentStep = 6;
        }else{
          this.mainStore.currentStep--
        }
      },
    }
  }
</script>

<style scoped>
.steps{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.pagination{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 64px;
  width: 100%;
}

.step{
  min-height: 300px;
  max-height: 360px;                                                      
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-40%);
    width: 100%;
}

.step>img{
  height:160px;
}

p{padding: 15px;color:#E00047}
h1{align-self: center;color:#E00047}

.btn_next{
  background-color: transparent;
  border:2px solid #E00047;
  border-radius:8px;
  height: 48px;
  width: 80%;
  font-size:22px;
  font-weight: 600;
  color:#E00047
}

.back{
  margin:15px
}
</style>