import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import { manager } from './core';

export let mixer;

export class Model{ //ERMITA
    constructor(path){
        this.path = path;
        this.loader = new GLTFLoader(manager);
        this.model = null;
        this.floorAnim = null;
        this.load();
    }
    load(){
        this.loader.load(this.path,(gltf)=>{
            mixer = new THREE.AnimationMixer(gltf.scene);
            this.model = gltf.scene;
            this.model.scale.set(.02,.02,.02);
            this.floorAnim = mixer.clipAction(gltf.animations[7]);
            this.floorAnim.setLoop(THREE.LoopOnce);
            this.floorAnim.clampWhenFinished = true;
            this.ermitaAnim = mixer.clipAction(gltf.animations[6]);
            this.ermitaAnim.setLoop(THREE.LoopOnce);
            this.ermitaAnim.clampWhenFinished = true;
        });
    }
    playFloorAnim(){
        this.floorAnim.play().reset();
    }
    playErmitaAnim(){
        this.ermitaAnim.play().reset();
    }
}