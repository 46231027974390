import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';

const messages = {
    esp: {
      message: {
        paso_1:"Paso 1",
        paso_2:"Paso 2",
        paso_3:"Paso 3",
        paso_4:"Paso 4",
        paso_5:"Paso 5",
        paso_6:"Paso 6",
        start_button:'Comenzar',
        instrucciones_button:'Instrucciones',
        continue_button: "Continuar",
        titulo: 'Ermita de Nuestra Señora de Salas',
        step_1: "La experiencia consta de un tótem situado en la entrada y tres puntos de enfoque situados a lo largo de la ermita.",
        step_2: "Enfoca en la imagen del tótem para acceder a la vista en AR de la ermita",
        step_3: "Una vez dentro de la ermita, encuentra en el suelo los puntos de referencia para situarte sobre cada uno de ellos.",
        step_4: "Enfoca a los restos de las pinturas.",
        step_5: "Haz coincidir la línea de referencia con los restos de las pinturas para descubrir la recreación.",
        step_6: "Pulsa el botón de info para saber más sobre la escena de la pintura",
        titulo: 'Ermita de Santa María de Sales',
        introduction_title:"Introducció",
        introduction_description:`Bienvenidos a la ermita de Santa María de Salas de Viladecans, una iglesia con una nave datada del inicio del siglo XII y una cabecera pentagonal del siglo XVI, construidas sobre los restos de una villa romana.
        Seguramente que hacia principios del siglo XIV las paredes del interior de la nave fueron decoradas con unas pinturas murales góticas que han llegado a nuestros días muy dañadas.
        A pesar de ello, y gracias a la recreación digital de las partes mejor conservadas del lado norte y al sistema de la realidad aumentada, a continuación podrá ver los restos y también disfrutar de la experiencia de admirar la decoración mural tal y como lucía con su esplendor.
        `,      
        introduction_button: "Volver",
        la_passion_title: "La pasión de Cristo",
        la_passion_description: "La Pasión de Cristo es el pasaje de la vida de Jesús de Nazaret narrado en los cuatro Evangelios del Nuevo Testamento (San Marcos, San Mateo, San Lucas y San Juan), que transcurre entre la Santa Cena y su entierro. los restos de las pinturas que nos han llegado se han podido determinar buena parte de los personajes de las escenas del arrebato en el huerto de Getsemaní, de Cristo ante Pilato y de la Flagelación, de izquierda a derecha, que se han recreado.Más hacia la izquierda se intuyen pocos restos de la Crucifixión y, aún más allá y en dirección a la puerta del templo, hay algún vestigio que probablemente corresponde al episodio de la Santa Cena, anterior al arrepentimiento dentro el relato de la Pasión. Las tres escenas están delimitadas por columnas y arcos casi planos, y enmarcados por cenefas a modo de grecas, con cortinajes en la parte inferior.",
        sc2_title: "EL PRENDIMIENTO EN GETSEMANÍ",
        sc2_description: "La representación del episodio del prendimiento de Jesús en Getsemaní (el huerto de los Olivos) después de la oración era la mejor conservada y fácilmente interpretable cuando se restauraron las pinturas. Según el Evangelio de San Juan , Jesús fue arrestado por soldados y guardas del templo armados y con linternas y antorchas.Entonces Simón Pedro cortó la oreja derecha al criado del sumo sacerdote con una espada, en los demás Evangelios se menciona que Jesús fue identificado por Judas en besarlo.El maestro de Sales representa a los soldados vestidos con cota de malla y casco de época medieval y Jesús dando pan a Judas, hecho que se relaciona con el anuncio de su traición durante la Santa Cena.",
        sc3_title:"CRISTO ANTE PILADO",
        sc3_description:"Cristo, maniatado y acusado de afirmar que era el rey de los judíos, fue llevado ante Ponç Pilato, el gobernador romano de Judea, para que le juzgara y le condenara. Pilato le preguntó si lo era y Jesús no lo negó.Cada año, por la fiesta de Pascua, el gobernador tenía la costumbre de dejar libre al preso que la gente quería.Entonces había un llamado Barrabás, preguntó si querían liberar a éste o Jesús y contestaron que Barrabás.",
        sc4_title:"LA FLAGELACIÓN",
        sc4_description:"Cuando la gente pidió la liberación de Barrabás les preguntó qué tenía que hacer con Jesús y le contestaron que le crucificaran. Sin embargo replicó que qué mal había hecho e insistieron, aún más fuerte, que el crucificaran.Ante esto, lo entregó pero antes lo hizo azotar, le puso una corona de espinas y un manto de púrpura.En la escena se representa a Jesús atado a una columna mientras los verdugos lo flagelan con el el flagrum taxillatum romano, compuesto por un mango de madera y tres correas de cuero con bolas de plomo.El verdugo de la derecha lleva una larga capa con capucha, tal y como debían vestir los judíos a principios del siglo XIV según las ordenaciones reales de la Corona de Aragón."
      }
    },
    eng: {
      message: {
        paso_1:"Step 1",
        paso_2:"Step 2",
        paso_3:"Step 3",
        paso_4:"Step 4",
        paso_5:"Step 5",
        paso_6:"Step 6",
        start_button:'Start',
        instrucciones_button:'Instructions',
        continue_button: "Next",
        titulo: 'Chapel of Santa Maria de Sales',
        step_1: "The experience consists of a totem pole at the entrance and three focus points along the hermitage.",
        step_2: "Focus on the image of the totem to access the Augmented Reality view of the chapel.",
        step_3: "Once inside the hermitage, find the landmarks on the ground to place yourself on each one of them",
        step_4: "Focus on the remains of the paintings",
        step_5: "Match the reference line with the remains of the paintings to uncover the recreation",
        step_6: "Click the info button to find out more about the painting scene",
        title: 'Chapel of Santa Maria de Sales',
        introduction_title: "Introduction",
        introduction_description:`Welcome to the hermitage of Santa Maria de Salas in Viladecans, a church with a nave dating from the beginning of the 12th century and a pentagonal chancel from the 16th century, built on the remains of a Roman villa.
        It is likely that around the beginning of the 14th century, the walls of the interior of the nave were decorated with Gothic mural paintings that have survived to the present day in a very damaged state.
        Despite this, and thanks to the digital recreation of the best preserved parts of the north side and the augmented reality system, you will be able to see the remains and also enjoy the experience of admiring the mural decoration as it looked in its splendour.
        `,
        introduction_button: "Back",
        la_passion_title: "The Passion of Christ",
        la_passion_description: "The Passion of Christ is the story of the life of Jesus of Natzaret narrated in the four Gospels of the New Testament (Saint Mark, Saint Matthew, Saint Luke and Saint John), which takes place between the Holy Supper and his burial. From the remains of the paintings that have survived, we have been able to determine many of the characters in the scenes of the arrest in the Garden of Gethsemane, Christ before Pilate and the Flagellation, from left to right, which have been recreated. Further to the left, some remains of the Crucifixion can be made out and, further on and towards the door of the temple, there is some clothing that probably corresponds to the episode of the Holy Soup, prior to the capture in the Passion narrative. The three scenes are delimited by columns and almost flat arches, and are framed by sanefes in the form of fretwork, with curtains at the bottom",
        sc2_title: "THE GATHERING OF GETSEMANI",
        sc2_description: "The depiction of the episode of Jesus' arrest in Gethsemane (the Garden of Olives) after the prayer was the best preserved and the easiest to interpret when the paintings were restored. According to the Gospel of John, Jesus was arrested by armed soldiers and temple guards armed with lanterns and torches. Simon Pere then pierced the right ear of the high priest's servant with a thorn; in the other Gospels Jesus is said to have been identified by Jude kissing him. Sales' master depicts the soldiers dressed in medieval chain mail and helmet and Jesus giving bread to Judes, which is related to the announcement of his betrayal during the Holy Soup",
        sc3_title: "CHRIST IN FRONT OF PILATE",
        sc3_description: "Christ, bound by the hands and accused of claiming to be the king of the Jews, was brought before Pontius Pilate, the Roman governor of Judea, to be judged and condemned. Pilate asked him if he was, and Jesus did not deny it. Every year, for the feast of Easter, the governor had the custom of releasing the prisoner that the people wanted. At that time there was one called Barrabàs, he asked if they wanted to release him or Jesús and they answered that Barrabàs.",
        sc4_title: "THE FLAGELATION",
        sc4_description: "When the people demanded the release of Barabbas, he asked them what he was going to do with Jesus and they answered that he should be crucified. However, he replied that what evil he had done and they insisted, even more strongly, that he should be crucified. In the face of this, he handed him over, but before doing so he had him executed, he put on a crown of thorns and a cloth of cloth. The scene depicts Jesus tied to a pillar, while the executioners scourge him with the Roman flagrum taxillatum, made up of a whip of wood and three leather straps with lead bowls. The right-hand buckler wears a long cape with a hood, as the judges had to wear at the beginning of the 14th century according to the royal ordinances of the Crown of Aragon"
      }
    },
    cat: {
      message:{
        paso_1:"Pas 1",
        paso_2:"Pas 2",
        paso_3:"Pas 3",
        paso_4:"Pas 4",
        paso_5:"Pas 5",
        paso_6:"Pas 6",
        start_button:'Començar',
        instrucciones_button:'Instruccions',
        continue_button: "Següent",
        titulo: 'Ermita de la Mare de Déu de Sales',
        step_1: "L'experiència consta d'un tòtem situat a l'entrada i tres punts d'enfocament situats al llarg de l'ermita.",
        step_2: "Enfoca en la imatge del tòtem  per a accedir a  la vista en AR de l'ermita",
        step_3: "Una vegada dins de l'ermita, troba en el sòl els punts de referència per a situar-te sobre cadascun d'ells.",
        step_4: "Enfoca a les restes de les pintures.",
        step_5: "Fes coincidir la línia de referència amb les restes de les pintura per a descobrir la recreació.",
        step_6: "Prem el botó de info per a saber mes sobre l'escena de la pintura",
        introduction_title:"Introducció",
        introduction_description:`Benvinguts a l’ermita de Santa Maria de Sales de Viladecans, una església amb una nau datada de l’inici del segle XII i una capçalera pentagonal del segle XVI, bastides sobre les restes d’una vil·la romana.
        Segurament que cap a principis del segle XIV les parets de l’interior de la nau van ser decorades amb unes pintures murals gòtiques que han arribat als nostres dies molt malmeses. 
        Malgrat això, i gràcies a la recreació digital de les parts millor conservades del costat nord i al sistema de la realitat augmentada, tot seguit podreu veure’n les restes i també gaudir de l’experiència d’admirar la decoració mural tal i com lluïa amb el seu esplendor.
        `,
        introduction_button: "Tornar",
        la_passion_title: "La passió de Crist",
        la_passion_description: "La Passió de Crist és el passatge de la vida de Jesús de Natzaret narrat als quatre Evangelis del Nou Testament (Sant Marc, Sant Mateu, Sant Lluc i Sant Joan), que transcorre entre el Sant Sopar i el seu enterrament. De les restes de les pintures que ens han pervingut s’ha pogut determinar bona part dels personatges de les escenes del prendiment a l’hort de Getsemaní, de Crist davant Pilat i de la Flagel·lació, d’esquerra a dreta, que s’han recreat. Més cap a l’esquerra s’intueixen poques restes de la Crucifixió i, encara més enllà i en direcció a la porta del temple, hi ha algun vestigi que probablement correspon a l’episodi del Sant Sopar, anterior al prendiment dins el relat de la Passió. Les tres escenes estan delimitades per columnes i arcs gairebé plans, i emmarcats per sanefes a manera de greques, amb cortinatges a la part inferior.",
        sc2_title: "EL PRENDIMENT A GETSEMANÍ",
        sc2_description: "La representació de l’episodi del prendiment de Jesús a Getsemaní (l’hort de les Oliveres) després de l’oració era la més ben conservada i fàcilment interpretable quan es van restaurar les pintures. Segons l’Evangeli de Sant Joan, Jesús va ser arrestat per soldats i guardes del temple armats i amb llanternes i torxes. Llavors Simó Pere va tallar l'orella dreta al criat del gran sacerdot amb una espasa; en els altres Evangelis s’esmenta que Jesús fou identificat per Judes en besar-lo. El mestre de Sales representa els soldats vestits amb cota de malla i casc d’època medieval i Jesús donant pa a Judes, fet que es relaciona amb l’anunci de la seva traïció durant el Sant Sopar.",
        sc3_title:"CRIST DAVANT PILAT",
        sc3_description:"Crist, lligat de mans i acusat d’afirmar que era el rei dels jueus, va ser portat davant de Ponç Pilat, el governador romà de la Judea, perquè el  jutgés i el condemnés. Pilat li va preguntar si ho era i Jesús no ho va negar. Cada any, per la festa de Pasqua, el governador tenia el costum de deixar lliure el pres que la gent volia. Aleshores n’hi havia un anomenat Barrabàs, preguntà si volien alliberar aquest o Jesús i van contestar que Barrabàs.",
        sc4_title:"LA FLAGEL·LACIÓ",
        sc4_description:"Quan la gent demanà l’alliberament de Barrabàs va preguntar-los què havia de fer amb Jesús i li van contestar que el crucifiquessin. Tot i això va replicar que quin mal havia fet i van insistir, encara més fort, que el crucifiquessin. Davant d’això, el va entregar però abans el va fer assotar, li va posar una corona d’espines i un mantell de porpra. En l’escena es representa Jesús lligat a una columna mentre els botxins el flagel·len amb el el flagrum taxillatum romà, compost per un mànec de fusta i tres corretges de cuir amb boles de plom. El botxí de la dreta porta una llarga capa amb caputxa, tal i com havien de vestir els jueus a principis del segle XIV segons les ordinacions reials de la Corona d’Aragó."
      }
    }
  }

const i18n = createI18n({
    locale: 'cat',
    messages
});

const pinia = createPinia();

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(pinia);
app.mount('#app');


