<template>
   <div class="modal_container" v-show="this.mainStore.isModalWindowOpened === true">
        <div class="modal_window">
            <h1>{{this.mainStore.modalTitle}}</h1>
            <hr>
            <p>{{this.mainStore.modalDescription}}</p>
        </div>
        <button class="button_close" @click="closeModal()">{{this.mainStore.modalButtonName}}</button>
   </div> 
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
export default{
    name:'ModalComponent',
    setup(){
        const mainStore = useMainStore();
        return {mainStore}
    },
    methods:{
        closeModal: function(){
            this.mainStore.isModalWindowOpened = false;
        }
    }
}
</script>

<style scoped>
.modal_container{
    position: absolute;
    width:100%;
    height:100%;
    z-index: 15;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:15px;
    align-items: center;
}
.modal_window{
    width:90%;
    height:60vh;
    padding:15px;
    background-color: #fff;
    overflow:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border:4px solid #E00047;
    font-weight: 600;
}

.button_close{
    background-color: #E00047;
    color:#fff;
    width: 90%;
    height:48px;
    border-radius: 12px;
    outline: none;
    border:none;
    box-shadow: 2px 2px 10px 2px rgba(0,0,0,.5);
}

hr{
    width:100px;
    border: 1px solid #E00047;
}
</style>