<template>
  <!--<nav>
    <router-link to="/">home</router-link> |
    <router-link to="/instructions">About</router-link>
  </nav>-->

  <router-view/>

</template>

<style>
*{margin: 0;padding: 0;box-sizing: border-box;font-family: Avenir, Helvetica, Arial, sans-serif;}
</style>
