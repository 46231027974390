import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {manager} from './core'

const loader = new GLTFLoader(manager);

export class CurvedGeometry{
    constructor(material,name){
        this.path = './models/curva_uv_new.glb';
        this.mesh= null;
        this.material = material;
        this.name = name;
        this.loadModel();
    }
    loadModel(){
        loader.load(this.path, (gltf)=>{
            this.mesh = gltf.scene;
            this.mesh.rotation.x = THREE.MathUtils.degToRad(-90);
            this.mesh.scale.set(1.5,1.5,1.5);//first
            this.mesh.position.set(-.1,-.3,.08);//first
            if(this.name === 'sc2'){
                this.mesh.scale.set(1.5,1.5,1.5);//first
                this.mesh.position.set(-.1,-.3,.06);//first  
            }else if(this.name === 'sc3'){
                this.mesh.scale.set(1.8,1.8,1.8);//first
                this.mesh.position.set(-.015,0,.07);//first  
            }else if(this.name==='sc4'){
                this.mesh.scale.set(1.9,1.9,1.9);
                this.mesh.position.set(.05,0,.01);
            }
            this.addMaterial();
        });
    }
    addMaterial(){
        this.mesh.children[0].material = this.material
    }

}