import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import InstructionsView from '../views/InstructionsView.vue'
import StartView from '../views/StartView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    
  },
  {
    path: '/start',
    name: 'Start',
    component: StartView

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: InstructionsView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.ROUTER_BASE,
  routes
})

export default router
