import { defineStore } from "pinia";

export const useMainStore = defineStore('mainStore',{
    state: ()=> ({
        currentStep: 1,
        selectedHotspot: null,
        modalSetted: '',
        currentHotspot:null,
        currentHotspotIndex: 0,
        isTracking: false,
        modalTitle: null,
        modalDescription: null,
        modalButtonName: null,
        isModalWindowOpened: false,
        sc2Loaded:false,
        sc3Loaded:false,
        sc4Loaded:false,
        isEverythingLoaded:false,
        isSc1Shown:false,
        isSc2Shown:false,
        isSc3Shown:false,
        isSc4Shown:false,
    })
})