<template>
  <keep-alive>
  <div class="home">
      <div class="header">
        <div class="logo"><img :src="Logo" alt="Diputació de Barcelona"></div>
        <div class="lang">
          <span @click="changeLocale('cat')"><img :src="BanCAT" alt="Catalonia Flag"></span>
          <span @click="changeLocale('esp')"><img :src="BanESP" alt="Spain Flag"></span>
          <span @click="changeLocale('eng')"><img :src="BanUK" alt="United Kingdom Flag"></span>
        </div>
      </div>
      <div class="title">
        <h1>{{ $t("message.titulo") }}</h1>
        <h2>Viladecans</h2>
      </div>


      <img class="loader" :src="Loader_gif" alt="" v-show="this.mainStore.isEverythingLoaded === false">

      <div class="buttons" v-show="this.mainStore.isEverythingLoaded === true">
        <router-link to="/start"><button class="primary">{{$t('message.start_button')}}</button></router-link>
        <router-link to="/instructions"><button class="secondary">{{$t('message.instrucciones_button')}}</button></router-link>
      </div>
  </div>
</keep-alive>
</template>

<script>
// @ is an alias to /src
import Logo from '../assets/logo_viladecans.svg';
import BanCAT from '../assets/BanCAT.svg';
import BanESP from '../assets/BanESP.svg';
import BanUK from '../assets/BanUK.svg';
import Loader_gif from '../assets/loader.gif'
import { useMainStore } from '@/stores/mainStore';

export default {
  name: 'HomeView',
  components: {
    
  },
  setup(){
    const mainStore = useMainStore();

    return {mainStore}
  },
  methods:{
    changeLocale(lang){
      this.$root.$i18n.locale = lang;
    }
  },
  data:function(){
    return{
      Logo: Logo,
      BanCAT: BanCAT,
      BanESP: BanESP,
      BanUK:BanUK,
      Loader_gif: Loader_gif
    }
  }
}
</script>

<style scoped>
.home{
  position: absolute;
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:15px;
  top:0;
  left:0
}

.header{
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}

.header>div{
  align-self: center;
}

.lang{
  display: flex;
  flex-direction: row;
  gap:10px
}

.title{
  width: 195px;
  text-align: right;
  align-self: flex-end;
  margin:15px;
  display: flex;
  flex-direction: column;
  gap:15px
}

h1{
  color: #E00047;
}

h2{
  color: #E00047;
  font-weight: 400;
}

.buttons{margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
}

.home button{
  border:none;
  outline:none;
  width:180px;
  padding: 10px;
  border-radius: 8px;
  font-weight: 600;
}

.home button.primary{
  background-color: #E00047;
  color:#fff;
}

.home button.secondary{
  background-color: #FFF;
  border: 1px solid #E00047;
  color:#E00047;
}

.loader{
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>