<template>
    <ModalComponent/>
    <!--<div class="header">
        <img :src="Info" alt="Info" v-show="mainStore.selectedHotspot === 1 || mainStore.selectedHotspot === 2 || mainStore.selectedHotspot === 3 " @click="setModal(this.mainStore.modalSetted)" >
    </div>-->

    <div class="videos" style="display:none">
        <video id="sc_2" src="/video/escena_2.mp4" playsinline muted></video>
        <video id="sc_3" src="/video/escena_3.mp4" playsinline muted></video>
        <video id="sc_4" src="/video/escena_4.mp4" playsinline muted></video>
    </div>

    <img id="sc2_img" src="@/assets/img/escena_2.png" alt="image" v-show="this.mainStore.isSc2Shown===true && this.mainStore.isTracking===false && this.mainStore.selectedHotspot === 1">
    <img id="sc3_img" src="@/assets/img/escena_3.png" alt="image" v-show="this.mainStore.isSc3Shown===true && this.mainStore.isTracking===false && this.mainStore.selectedHotspot === 2">
    <img id="sc4_img" src="@/assets/img/escena_4.png" alt="image" v-show="this.mainStore.isSc4Shown===true && this.mainStore.isTracking===false && this.mainStore.selectedHotspot === 3">

    <img class="hotspot_stroke" :src="this.Totem" v-show="this.mainStore.selectedHotspot === 0 && this.mainStore.currentHotspot !=0 ">
    <img class="hotspot_stroke" :src="this.Stroke3" v-show="this.mainStore.selectedHotspot === 1 && this.mainStore.currentHotspot != 1 && this.mainStore.isSc2Shown===false">
    <img class="hotspot_stroke" :src="this.Stroke4" v-show="this.mainStore.selectedHotspot === 2 && this.mainStore.currentHotspot !=2 && this.mainStore.isSc3Shown===false">
    <img class="hotspot_stroke" :src="this.Stroke5" v-show="this.mainStore.selectedHotspot === 3 && this.mainStore.currentHotspot !=3 && this.mainStore.isSc4Shown===false">


    <div class="hotspot_1_menu" v-show="this.mainStore.currentHotspotIndex === 0 && this.mainStore.isTracking===true && this.mainStore.currentHotspot === 0 && this.mainStore.selectedHotspot === 0">
        <button @click="setModal('introduction')">Introducció</button>
        <button @click="setModal('passion')">La passió de crist</button>
    </div>

    <div class="hotspots_menu" v-show="this.mainStore.modalTitle!=null && this.mainStore.selectedHotspot!=0">
        <button @click="setModal(this.mainStore.modalSetted)">{{this.mainStore.modalTitle}}</button>
    </div>

    <div class="options">
        <span @click="setCurrentHotspot(0)" :class="this.mainStore.selectedHotspot === 0 ? 'active' : ''">i</span>
            
        <span @click="setCurrentHotspot(1)" :class="this.mainStore.selectedHotspot === 1 ? 'active' : ''">1</span>
            
        <span @click="setCurrentHotspot(2)" :class="this.mainStore.selectedHotspot === 2 ? 'active' : ''">2</span>

        <span @click="setCurrentHotspot(3)" :class="this.mainStore.selectedHotspot === 3 ? 'active' : ''">3</span>
    </div>
</template>

<script>
    import * as THREE from 'three';
    import OnirixSDK from '@onirix/ar-engine-sdk';
    import Totem from "../assets/trazos/totem.svg";
    import Stroke1 from "../assets/trazos/01.svg";
    import Stroke2 from "../assets/trazos/02.svg";
    import Stroke3 from "../assets/trazos/03.svg";
    import Stroke4 from "../assets/trazos/04.svg";
    import Stroke5 from "../assets/trazos/05.svg";
    import Back from "../assets/arrow_back.svg";
    import Info from "../assets/BT_info.svg";
    import { useMainStore } from '@/stores/mainStore';
    import {Model,mixer} from '@/core/model';
    import ModalComponent from '@/components/ModalComponent.vue';
    import { CurvedGeometry } from '@/core/curved';

    const ermita = new Model("/models/ermita.glb");

    

    export default{
    name: "StartView",
    setup() {
        const mainStore = useMainStore();
        return { mainStore };
    },
    data: function () {
        return {
            Back: Back,
            Info: Info,
            Totem: Totem,
            Stroke1: Stroke1,
            Stroke2: Stroke2,
            Stroke3: Stroke3,
            Stroke4: Stroke4,
            Stroke5: Stroke5
        };
    },
    methods: {
        setCurrentHotspot: function (num) {
            this.mainStore.currentHotspotIndex = num;
            this.mainStore.selectedHotspot = num;
            if(num===0){
                console.log(NaN)
            }else if(num===1){
                this.mainStore.modalSetted = 'sc2';
                this.mainStore.modalTitle = this.$t("message.sc2_title");
                this.mainStore.modalDescription = this.$t("message.sc2_description");
                this.mainStore.modalButtonName = this.$t("message.introduction_button");
            }else if(num===2){
                this.mainStore.modalSetted = 'sc3'
                this.mainStore.modalTitle = this.$t("message.sc3_title");
                this.mainStore.modalDescription = this.$t("message.sc3_description");
                this.mainStore.modalButtonName = this.$t("message.introduction_button");
            }else if(num===3){
                this.mainStore.modalSetted = 'sc4'
                this.mainStore.modalTitle = this.$t("message.sc4_title");
                this.mainStore.modalDescription = this.$t("message.sc4_description");
                this.mainStore.modalButtonName = this.$t("message.introduction_button");
            }            
        },
        setModal: function (arg) {
            if (arg === "introduction") {
                this.mainStore.modalTitle = this.$t("message.introduction_title");
                this.mainStore.modalDescription = this.$t("message.introduction_description");
                this.mainStore.modalButtonName = this.$t("message.introduction_button");
                this.mainStore.isModalWindowOpened = true;
            }else if(arg==="passion"){
                this.mainStore.modalTitle = this.$t("message.la_passion_title");
                this.mainStore.modalDescription = this.$t("message.la_passion_description");
                this.mainStore.modalButtonName = this.$t("message.introduction_button");
                this.mainStore.isModalWindowOpened = true;
            }else if(arg="sc2"){
                
                this.mainStore.isModalWindowOpened = true;
            }else if(arg="sc3"){
                
                this.mainStore.isModalWindowOpened = true;
            }else if(arg="sc4"){
                
                this.mainStore.isModalWindowOpened = true;
            }
        }
    },
    mounted() {
        if(this.mainStore.isEverythingLoaded === false){
            window.location.href = '/';
        }
        const video_sc2 = document.getElementById('sc_2');
        video_sc2.load();
        const video_sc3 = document.getElementById('sc_3');
        video_sc3.load();
        const video_sc4 = document.getElementById('sc_4');
        video_sc4.load();

        function vertexShader() {
                return `
                varying vec2 vUv;
                void main( void ) {     
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
                }
            `
            }
        
        function fragmentShader() {
                return `
                uniform vec3 keyColor;
                uniform float similarity;
                uniform float smoothness;
                varying vec2 vUv;
                uniform sampler2D map;
                void main() {

                    vec4 videoColor = texture2D(map, vUv);
            
                    float Y1 = 0.299 * keyColor.r + 0.587 * keyColor.g + 0.114 * keyColor.b;
                    float Cr1 = keyColor.r - Y1;
                    float Cb1 = keyColor.b - Y1;
                    
                    float Y2 = 0.299 * videoColor.r + 0.587 * videoColor.g + 0.114 * videoColor.b;
                    float Cr2 = videoColor.r - Y2; 
                    float Cb2 = videoColor.b - Y2; 
                    
                    float blend = smoothstep(similarity, similarity + smoothness, distance(vec2(Cr2, Cb2), vec2(Cr1, Cb1)));
                    gl_FragColor = vec4(videoColor.rgb, videoColor.a * blend); 
                }
            `
        }
    
        let videoTexture_sc2 = new THREE.VideoTexture(video_sc2);
        videoTexture_sc2.needsUpdate;
        videoTexture_sc2.minFilter = THREE.LinearFilter;
        videoTexture_sc2.magFilter = THREE.LinearFilter;
        videoTexture_sc2.flipY = false;
        videoTexture_sc2.format = THREE.RGBAFormat;
        videoTexture_sc2.crossOrigin = 'anonymous';

        let videoTexture_sc3 = new THREE.VideoTexture(video_sc3);
        videoTexture_sc3.needsUpdate;
        videoTexture_sc3.minFilter = THREE.LinearFilter;
        videoTexture_sc3.magFilter = THREE.LinearFilter;
        videoTexture_sc3.flipY = false;
        videoTexture_sc3.format = THREE.RGBAFormat;
        videoTexture_sc3.crossOrigin = 'anonymous';

        let videoTexture_sc4 = new THREE.VideoTexture(video_sc4);
        videoTexture_sc4.needsUpdate;
        videoTexture_sc4.minFilter = THREE.LinearFilter;
        videoTexture_sc4.magFilter = THREE.LinearFilter;
        videoTexture_sc4.flipY = false;
        videoTexture_sc4.format = THREE.RGBAFormat;
        videoTexture_sc4.crossOrigin = 'anonymous';


        
        let mesh_sc2,mesh_sc3,mesh_sc4;
        let geometry = new THREE.PlaneGeometry(.8,.8);
        let texture = new THREE.MeshBasicMaterial({map: videoTexture_sc2});

        //mesh_sc2 = new THREE.Mesh(geometry,texture);
        mesh_sc2 = new CurvedGeometry(new THREE.ShaderMaterial({
                transparent: true,
                uniforms: {
                    map: { value: videoTexture_sc2 },
                    keyColor: { value: [0.0, 1.0, 0.0] },
                    similarity: { value: 0.75 },
                    smoothness: { value: 0.01 },
                },
                vertexShader: vertexShader(),
                fragmentShader: fragmentShader(),
            }),'sc2');
        //mesh_sc2.position.set(0,.5,0);
        //mesh_sc2.rotation.x = THREE.MathUtils.degToRad(-90);

        mesh_sc3 = new CurvedGeometry(new THREE.ShaderMaterial({
                transparent: true,
                uniforms: {
                    map: { value: videoTexture_sc3 },
                    keyColor: { value: [0.0, 1.0, 0.0] },
                    similarity: { value: 0.75 },
                    smoothness: { value: 0.0 },
                },
                vertexShader: vertexShader(),
                fragmentShader: fragmentShader(),
            }),'sc3');
        

        mesh_sc4 = new CurvedGeometry(new THREE.ShaderMaterial({
                transparent: true,
                uniforms: {
                    map: { value: videoTexture_sc4 },
                    keyColor: { value: [0.0, 1.0, 0.0] },
                    similarity: { value: 0.75 },
                    smoothness: { value: 0.0 },
                },
                vertexShader: vertexShader(),
                fragmentShader: fragmentShader(),
            }),'sc4');

        
        let renderer, scene, camera;
        const mainStore = this.mainStore;
        function setupRenderer(rendererCanvas) {
            const width = rendererCanvas.width;
            const height = rendererCanvas.height;
            // Initialize renderer with rendererCanvas provided by Onirix SDK
            renderer = new THREE.WebGLRenderer({ canvas: rendererCanvas, alpha: true });
            renderer.setClearColor(0, 0);
            renderer.setSize(width, height);
            // Ask Onirix SDK for camera parameters to create a 3D camera that fits with the AR projection.
            const cameraParams = OX.getCameraParameters();
            camera = new THREE.PerspectiveCamera(cameraParams.fov, cameraParams.aspect, 0.1, 1000);
            camera.matrixAutoUpdate = false;
            // Create an empty scene
            scene = new THREE.Scene();
            // Add some lights
            const ambientLight = new THREE.AmbientLight(0xffffff, 0.4);
            scene.add(ambientLight);
            const hemisphereLight = new THREE.HemisphereLight(0xffffff, 1);
            scene.add(hemisphereLight);
        }
        function updatePose(pose) {
            let modelViewMatrix = new THREE.Matrix4();
            modelViewMatrix = modelViewMatrix.fromArray(pose);
            camera.matrix = modelViewMatrix;
            camera.matrixWorldNeedsUpdate = true;
        }
        function onResize() {
            const width = renderer.domElement.width;
            const height = renderer.domElement.height;
            const cameraParams = OX.getCameraParameters();
            camera.fov = cameraParams.fov;
            camera.aspect = cameraParams.aspect;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        }
        function render() {
            renderer.render(scene, camera);
        }
        const clock = new THREE.Clock();
        function renderLoop() {
            render();
            if (mixer != null)
                mixer.update(clock.getDelta());

            if(mainStore.selectedHotspot === 0){
                ermita.model.visible = true;
                mesh_sc2.mesh.visible = false;
                mesh_sc3.mesh.visible = false;
                mesh_sc4.mesh.visible = false;  
            }else if(mainStore.selectedHotspot === 1){
                ermita.model.visible = false;
                mesh_sc2.mesh.visible = true;
                mesh_sc3.mesh.visible = false;
                mesh_sc4.mesh.visible = false;
            }else if(mainStore.selectedHotspot === 2){
                ermita.model.visible = false;
                mesh_sc2.mesh.visible = false;
                mesh_sc3.mesh.visible = true;
                mesh_sc4.mesh.visible = false;
            }else if(mainStore.selectedHotspot === 3){
                ermita.model.visible = false;
                mesh_sc2.mesh.visible = false;
                mesh_sc3.mesh.visible = false;
                mesh_sc4.mesh.visible = true;
            }else if(mainStore.selectedHotspot === null){
                ermita.model.visible = false;
                mesh_sc2.mesh.visible = false;
                mesh_sc3.mesh.visible = false;
                mesh_sc4.mesh.visible = false;
            }
            requestAnimationFrame(() => renderLoop());
        }
        let OX = new OnirixSDK("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsInByb2plY3RJZCI6MjI0NjksInJvbGUiOjMsImlhdCI6MTY0MjYxNDAwNn0.CJuRmqKg9NTqLC73huGZFy-6ZI9Djg1AIniw5sTmqTk");
        let config = {
            mode: OnirixSDK.TrackingMode.Image
        };
        OX.init(config).then(rendererCanvas => {
            // Setup ThreeJS renderer
            
            setupRenderer(rendererCanvas);
            // All loaded, so hide loading screen
            // Initialize render loop
            renderLoop();
            OX.subscribe(OnirixSDK.Events.OnDetected, function (id) {
                console.log("Detected Image: " + id);
                // Diplay 3D model
                if (id === "6c19e9e86b0c4810b87638e59d090112") { //TOTEM
                    
                    ermita.model.visible = true;
                    mainStore.currentHotspot = 0;
                    mainStore.isTracking = true;
                    console.log("totem");
                    scene.add(ermita.model);
                    ermita.playFloorAnim();
                    ermita.playErmitaAnim();
                    rendererCanvas.style.opacity = 1
                    
                }
                else if (id === "223c4fd766d14616b65f1c9b795dacf0") { //IMG 3
                    mainStore.currentHotspot = 1;
                    mainStore.isTracking = true;
                    scene.add(mesh_sc2.mesh);
                    mainStore.isSc2Shown = true;
                    document.getElementById('sc_2').play();
                    console.log("img3");
                    rendererCanvas.style.opacity = .80;
                    
                }
                else if (id === "9c0abdc6d63e4733a9eb0930ce59e404") { //IMG 4
                    mainStore.currentHotspot = 2;
                    mainStore.isTracking = true;
                    scene.add(mesh_sc3.mesh);
                    mainStore.isSc3Shown = true;
                    document.getElementById('sc_3').play();
                    console.log("img4");
                    rendererCanvas.style.opacity = .80;
                }
                else if (id === "12021d2a604e459d827c418f5fc0312c") { //IMG 5
                    mainStore.currentHotspot = 3;
                    mainStore.isTracking = true;
                    scene.add(mesh_sc4.mesh);
                    mainStore.isSc4Shown = true;
                    document.getElementById('sc_4').play();
                    console.log("img5");
                    rendererCanvas.style.opacity = .80
                }
                // It is useful to synchronize scene background with the camera feed
                scene.background = new THREE.VideoTexture(OX.getCameraFeed());
            });
            OX.subscribe(OnirixSDK.Events.OnPose, function (pose) {
                updatePose(pose);
            });
            OX.subscribe(OnirixSDK.Events.OnLost, function (id) {
                console.log("Lost Image: " + id);
                mainStore.isTracking = false;
                mainStore.currentHotspot = null;
                // Hide 3D model
                if (id === "6c19e9e86b0c4810b87638e59d090112") { //TOTEM
                    console.log("totem");
                    scene.remove(ermita.model);
                    camera.lookAt(0,0,0);
                    camera.position.set(0,0,0);
                    mainStore.isTracking = false;
                }
                else if (id === "223c4fd766d14616b65f1c9b795dacf0") { //IMG 3
                    console.log("img3");
                    scene.remove(mesh_sc2.mesh);
                    mesh_sc2.mesh.position.x = 0;
                    mesh_sc2.mesh.position.y = 0;
                    mesh_sc2.mesh.position.z = 0;
                    camera.lookAt(0,0,0);
                    camera.position.x = 0;
                    mainStore.isTracking = false;

                    document.getElementById('sc_2').pause();
                    document.getElementById('sc_2').currentTime = 0;
                }
                else if (id === "9c0abdc6d63e4733a9eb0930ce59e404") { //IMG 4
                    console.log("img4");
                    scene.remove(mesh_sc3.mesh);
                    mesh_sc3.mesh.position.set(0,0,0);
                    camera.lookAt(0,0,0);
                    camera.position.set(0,0,0);
                    mainStore.isTracking = false;

                    document.getElementById('sc_3').pause();
                    document.getElementById('sc_3').currentTime = 0;
                }
                else if (id === "12021d2a604e459d827c418f5fc0312c") { //IMG 5
                    console.log("img5");
                    scene.remove(mesh_sc4.mesh);
                    mainStore.isTracking = false;
                    mesh_sc4.mesh.position.set(0,0,0);
                    camera.lookAt(0,0,0);
                    camera.position.set(0,0,0);

                    document.getElementById('sc_4').pause();
                    document.getElementById('sc_4').currentTime = 0;
                }
                scene.background = null;
            });
            OX.subscribe(OnirixSDK.Events.OnResize, function () {
                onResize();
            });
        }).catch((error) => {
            // An error ocurred, chech error type and display it
            switch (error.name) {
                case "INTERNAL_ERROR":
                    break;
                case "CAMERA_ERROR":
                    break;
                case "SENSORS_ERROR":
                    break;
                case "LICENSE_ERROR":
                    break;
            }
        });
    },
    components: { ModalComponent }
}
</script>

<style scoped>

#sc2_img{
    position:absolute;
    z-index: 10;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 320px;
}

#sc3_img{
    position:absolute;
    z-index:10;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 320px;
}

#sc4_img{
    position:absolute;
    z-index:10;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 320px;
}
.header{
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    padding:15px;
}
.options{
    width: 100%;
    position: absolute;
    bottom: 5px;
    transform:translateY(-20px);
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px 0 15px;
}

.options>span{
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border:4px solid #E00047;
    border-radius: 8px;
    font-weight: 600;
    color:#000;
}

.hotspots_menu{
    position:absolute;
    width: 100%;
    bottom:96px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.hotspots_menu>button{
    background-color: #E00047;
    color:#fff;
    font-weight: 600;
    width: 180px;
    height: 48px;
    border-radius: 12px;
    border:none;
    outline: none;
}

.hotspot_1_menu{
    position:absolute;
    width: 100%;
    bottom:96px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.hotspot_1_menu>button{
    background-color: #E00047;
    color:#fff;
    font-weight: 600;
    width: 125px;
    height: 48px;
    border-radius: 12px;
    border:none;
    outline: none;
}

.active{
    background-color: #E00047!important;
    color:#fff!important;
}

.hotspot_stroke{
    width: 50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>